import React from 'react'
import { Link } from 'react-router-dom';


const Syllabus = ({course}) => {
  return (
    course &&
        course.sections.map((section, index) => (
          <div className="accordion" key={index} id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapse${index}`}
                  aria-expanded="true"
                  aria-controls={`collapse${index}`}
                >
                  {section.title}
                </button>
              </h2>
              <div
                id={`collapse${index}`}
                className="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  {section.lessons.map((lesson, index) => (
                    <div key={index} className="m-2 mb-3">
                      {lesson.video && (
                        <Link
                          to={`/courses/${course._id}/sections/${section._id}/lessons/${lesson._id}`}
                        >
                          <i className="fa-solid fa-video-camera px-3"></i>
                          {lesson.video.title}
                        </Link>
                      )}
                      {lesson.pdf && (
                        <Link
                          to={`/courses/${course._id}/sections/${section._id}/lessons/${lesson._id}`}
                        >
                          <i className="fa-regular fa-file-pdf px-3"></i>
                          {lesson.pdf.title}
                        </Link>
                      )}
                      {/* <hr /> */}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))

  )
}

export default Syllabus
