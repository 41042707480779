import React, { useState } from 'react'
import { register } from './services/Api';
import { useNavigate } from 'react-router-dom';

const Register = () => {
  const [user,setUser]=useState("");
  const redirect =useNavigate();
  const onChange = (e)=>{
    setUser({...user,[e.target.name]: e.target.value })
  }
const handleSubmit = ()=>{
  register(user)
      .then((response) => {
        try {

          console.log("Registration response", response.data.data.user);
          setUser(response.data.data.user)
          localStorage.setItem("user", JSON.stringify(response.data.data.user))
          redirect("/home");
        } catch (err) {
          console.log("Registration error", err);
          console.error(err);
          alert("Invalid Username or Password");
        }
      })
      .catch((error) => {
        console.log(error);
        alert("Invalid Username or Password");
      });
  }



  return (
    <div className="container">
    <h2 className='text-left mx-2 mt-5 mb-3'>Registration Form</h2>
<form action="bootstrapform.php" method="post">
    <div className=" form-floating mb-3">
<input type="text" className="form-control col-4" id="name" value={user.name}  onChange={onChange} name="name"/>
<label htmlFor="floatingInput">First Name</label>
</div>
<div className=" form-floating mb-3">
<input type="email" className="form-control col-4" id="email" value={user.email} onChange={onChange} aria-describedby="emailHelp" name="email"/>
<label htmlFor="floatingInput">Email</label>
</div>
<div className=" form-floating mb-3">
<input type="number" className="form-control col-4" id="phone" value={user.phone} onChange={onChange} aria-describedby="emailHelp" name="phone"/>
<label htmlFor="floatingInput">Phone</label>
</div>
<div className=" form-floating mb-3">
<input type="password" className="form-control col-4" id="passowrd" value={user.password} onChange={onChange} name="password"/>
<label htmlFor="floatingInput">Password</label>
</div>
{/* <div className="">
<label htmlFor="cpasssword">Confirm password</label>
<input type="text" className="form-control" id="exampleInputlastname" name="cpassword"/>
</div> */}
<button type="submit" className="btn btn-primary mt-2 col-4" onClick={handleSubmit} name="create">Sign up</button>
</form>
</div>
  )
}

export default Register
