import { combineReducers } from '@reduxjs/toolkit';
import loadingReducer from '../modules/loader/reducer';
import courseReducer from "../modules/course/reducer";
import quizReducer from "../modules/quiz/reducer"

const rootReducer = combineReducers({
  loading: loadingReducer,
  course:courseReducer,
  quiz:quizReducer,
  
  // ... other reducers
});

export default rootReducer;
