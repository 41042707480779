
import './App.css';
import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,

} from "react-router-dom";
import Register from './Register';
import Login from './Login';
import Home from './components/home/Home';
import Course from './components/courses/Course';
import Lesson from './components/lesson/Lesson';
import Quiz from './components/quiz/Quiz';
import Spinner from './components/spinner/Spinner';
import { useSelector } from 'react-redux';
import Exam from './components/quiz/Exam';

function App() {
  const loading = useSelector(state => state.loading);
  return (
    <BrowserRouter>
      <Spinner show={loading} />
      <Routes>
        <Route path="/register" element={<Register />} />
        <Route path="/" element={<Login />}></Route>
        <Route path="/home" element={<Home />} />
        <Route path='/courses/:slug' element={<Course />} />
        <Route path='/courses/:courseId/sections/:sectionId/lessons/:lessonId' element={<Lesson />} />
        <Route path='/home/quiz/:id' element={<Quiz />} />
        <Route path='/quiz/:id/exam' element={<Exam/>}/>

      </Routes>
    </BrowserRouter>
  )
}

export default App;
