import React, { useEffect, useState } from "react";
import {
  Card,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Container,
  Row,
  Col,
  CardLink,
} from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { hideLoader, showLoader } from "../../redux/modules/loader/reducer";

const Quiz = () => {
  const [radioChecked, setRadioChecked] = useState(false);
  const quiz = useSelector((state)=>{
    console.log("use Selector quiz",state)
    return state.quiz.quiz
  })
  const redirect = useNavigate();
  let {id}= useParams();
  const dispatch = useDispatch();
  const action = { type: 'FETCH_QUIZ_BY_ID', payload: { id } };
  useEffect(()=>{
    fetchQuiz()
  })
  const handleRadioChange = () => {
    setRadioChecked(true);
  };
  const fetchQuiz = () =>{
    dispatch(showLoader())
    dispatch(action)
      dispatch(hideLoader())
  }
  const handleBeginExamClick = () => {
    if (radioChecked) {
      redirect(`/quiz/${id}/exam`)
    }
  };
  return (
    quiz &&
    <Container>
      <Row className="justify-content-center align-items-center mt-5">
        <Col md="6">
          <Card>
            <CardBody>
              <CardTitle tag="h6" className="text-center mb-2">
                {quiz.name}
              </CardTitle>
              <div className="details">
                <p className="card-text fw-bold mb-1">
                  Enrollment No:<span className="fw-lighter">386</span>
                </p>
                <p className="card-text fw-bold mb-1">
                  Course Name:<span className="fw-lighter">Demo</span>
                </p>
                <p className="card-text fw-bold mb-1">
                  Mobile No:<span className="fw-lighter">386356</span>
                </p>
                <p className="card-text fw-bold mb-1">
                  Email:<span className="fw-lighter">krishna@gmail.com</span>
                </p>
              </div>
              <hr style={{ height: "2px" }} />
              <CardSubtitle tag="h5" className="mb-2">
                Instructions
              </CardSubtitle>
              <CardText style={{ height: "60vh" }}></CardText>
              <Row className="justify-content-center ">
                <Col md="8">
                  <form>
                    <div className="row align-items-center">
                      <div className="col">
                        <div className="form-check form-check-inline mb-3">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="exams"
                            id="exams"
                            checked={radioChecked}
                            onChange={handleRadioChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="exams"
                          >
                            <CardLink href="/terms" className="mb-2">
                              I agree to the terms and condition for the exam
                            </CardLink>
                          </label>
                        </div>
                      </div>
                    </div>
                    <Button className="col-12 rounded-pill" color="primary" id="beginExam" disabled={!radioChecked} onClick={handleBeginExamClick}>
                      Begin Exam
                    </Button>
                  </form>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Quiz;
