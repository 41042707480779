import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./home.css";
import { getLoggedUser } from "../../utils/auth";
import Navbar from "../navbar/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { showLoader } from "../../redux/modules/loader/reducer";

const Home = () => {
  const [user, setUser] = useState(null);
  const courses = useSelector((state)=> {
    console.log("Home useSelector courses", state);
    return state.course.courses
  });
  const quizzes = useSelector((state)=>{
    console.log("Home useSelector quizzes", state.quiz);
    return state.quiz.quizzes
  });
  const dispatch = useDispatch();

  useEffect(() => {
    const loggedUser = getLoggedUser();
    setUser(loggedUser);
    console.log("Home useEffect user", loggedUser);
    fetchData();
  }, []);
  useEffect(() => {
    fetchQuizList();
  }, []);
  const fetchData = async () => {
    dispatch(showLoader())
   dispatch({type:"FETCH_COURSE"})
  };

  const fetchQuizList = async () => {
    dispatch(showLoader())
   dispatch({type:"FETCH_QUIZ"})
  };

 
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      // partialVisibilityGutter: 40
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <>
      <Navbar />
      <div className="product mb-3">
        <div className="container d-flex flex-column">
          {user && (
            <p className="container align-item-center  text-left ">
              Hello {user.name}
            </p>
          )}
          <p className="container product-title text-left ">
            Welcome to Kishul LMS
          </p>
        </div>
      </div>

      <Carousel
        responsive={responsive}
        partialVisible={false}
        renderButtonGroupOutside={true}
        containerclass="container c-container"
      >
        {courses && courses.map(
          (course, index) =>
            course.is_popular && (
              <div key={index} >
                <div className="card h-auto" style={{width:"16rem"}}
                >
                  <img
                    src={course.thumbnail}
                    className="card-img-top img-fluid m-auto"
                    alt="courses-slider"
                    style={{ height: "100px", width: "100px" }}
                  />
                  <div className="card-body">
                    <h5 className="card-title">{course.title}</h5>
                    <h6 className="card-text">{course.price}</h6>
                  </div>
                </div>
              </div>
            )
        )}
      </Carousel>

      <div className="course-wrapper container mt-5 me-1">
        <div className="course-card">
          {courses && courses.map((course, index) => (
            <Link key={index} to={`/courses/${course.slug}`}>
              <div
                className="card mb-3 "
                key={index}
                style={{ maxWidth: "1040px" }}
              >
                <div className="row g-0">
                  <div className="col-md-4">
                    <img
                      src={course.thumbnail}
                      className="img-fluid rounded-start"
                      alt="courses"
                      style={{ height: "100px" }}
                    />
                  </div>
                  <div className="col-md-6">
                    <div className="card-body">
                      <h5 className="card-title">{course.title}</h5>
                      {/* <p className="card-text">{course.long_desc}</p> */}
                      <p className="card-text">
                        <small className="text-muted">
                          {course.short_desc}
                        </small>
                      </p>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="card-body">
                      <h5>{course.price}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
        <div className="quiz-card container">
          {quizzes && quizzes.map((quiz, index) => (

            <div className="card h-auto w-25 m-2" key={index}>
              <div className="card-body">
              <Link key={index} to={`/home/quiz/${quiz._id}`}>

                <h5 className="card-title">{quiz.name}</h5>
                {/* <p className="card-text">
                  With supporting text below as a natural lead-in to additional
                  content.
                </p>
                <a href="#" className="btn btn-primary">
                  Button
                </a> */}
                </Link>
              </div>
            </div>

          ))}

        </div>
      </div>
    </>
  );
};

export default Home;
