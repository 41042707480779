import React, { useEffect, useState } from 'react'
import './Course.css'
import {  useParams } from 'react-router-dom';
import Navbar from '../navbar/Navbar';
import Syllabus from '../syllabus/Syllabus';
import QuillFormattedText from "../../utils/QuillText";
import { useDispatch, useSelector } from 'react-redux';
import { hideLoader, showLoader } from '../../redux/modules/loader/reducer';

const course = () => {
  const course = useSelector((state)=>{
    console.log("course by slug useSelector",state);
    return state.course.course
  });
  const [noOfLessons, setNoOfLessons] = useState(0);
  let {slug }  = useParams();
  const dispatch = useDispatch();
  const action = { type: 'FETCH_COURSE_BY_SLUG', payload: { slug } };

const fetchCourse = () =>{
  dispatch(showLoader())
  dispatch(action)
    dispatch(hideLoader())
}
useEffect(()=>{
  if (course && course.sections) {
    let count = 0;
    course.sections.forEach((section) => {
      count = count + section.lessons.length;
    });
    setNoOfLessons(count);
  }
}, [course]);
useEffect(()=>{
  fetchCourse()
})
  return (
    course &&
    <>
    <Navbar/>
<div className="container mt-2">
<nav aria-label="breadcrumb">
  <ol className="breadcrumb">
    <li className="breadcrumb-item"><a href="#">Home</a></li>
    <li className="breadcrumb-item"><a href="#">course</a></li>
    <li className="breadcrumb-item active" aria-current="page">{course.title}</li>
  </ol>
</nav>
</div>
<div className="container d-flex align-item-center mt-2 ">
    <div className="col-md-8">
    <div className="row mt-3">
    <h1>{course.title}</h1>
    <p className='my-2'>{course.short_desc}</p>
    <h5 className='my-3'>Syllabus</h5>
{
 course && course.sections && <Syllabus course={course}/> }

<div className="course-description my-3">


<h3>Description</h3>
<QuillFormattedText html={course.long_desc} />

</div>
</div>
</div>
    <div className="col-md-4">

    <div className="card mt-4" style={{width: "18rem"}}>
  <img src={course.thumbnail} className="card-img-top" alt="course-image"/>
  <div className="card-body">
  <a href="#" className="btn btn-primary col-md-12">Enroll Now</a>
  <hr />
    <h6 className="card-title px-2">What's included</h6>
    <p className="card-text px-2"><i className='fa fa-check pr-2'></i>{noOfLessons} lessons</p>
    <p className='card-text px-2'><i className='fa fa-check pr-2'></i>Online Accessbility </p>
  </div>
</div>
</div>
</div>
</>
  )
}

export default course
