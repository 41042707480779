import React, { useEffect, useState } from "react";
import {
  getCourse,
  getLesson,
} from "../../services/Api";
import { useParams } from "react-router-dom";
import Syllabus from "../syllabus/Syllabus";

const lesson = (props) => {
  // const [sections, setSections] = useState(null);
  const [course, setCourse] = useState(null);
  const [selectedLesson, setSelectedLesson] = useState({});
  const { courseId, sectionId, lessonId } = useParams();
  // console.log("id",courseId,sectionId,videoId)
  const fetchLesson = () => {
    getLesson(courseId, sectionId, lessonId)
      .then((res) => {
        console.log("get Lesson ", res.data.data.lesson);
        setSelectedLesson(res.data.data.lesson);
        console.log("id", courseId, sectionId, lessonId);
      })
      .catch((err) => {
        // checkAuth(error, redirect);
        console.log(err);
      });
  };
  const fetchCourse = () => {
    getCourse(courseId)
      .then((res) => {
        console.log("fetchCourse from video", res.data.data);
        setCourse(res.data.data.course);
      })
      .catch((err) => {
        // checkAuth(error, redirect);
        console.log(err);
      });
  };
  useEffect(() => {
    fetchCourse();

  }, []);
  useEffect(() => {
    fetchLesson();
  }, [lessonId]);

  return (
    <>
      <div className="row" style={{ height: "70vh" }}>
        <div className="col-md-8 video lesson-header">

            <h6 className="px-3">{course && course.title}</h6>
            <h3 className="px-3">
              {selectedLesson &&
                selectedLesson.video &&
                selectedLesson.video.title}
            </h3>
            <h3 className="px-3">
              {selectedLesson && selectedLesson.pdf && selectedLesson.pdf.title}
            </h3>


          <div style={{ height: "100%" }}>
            <iframe
              width="100%"
              height="100%"
              src={selectedLesson && selectedLesson.video &&  selectedLesson.video.url}
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              title="test player iframe vimeo"
            />
          </div>


        </div>
        <div className="col-md-4">
          <p className="m-1 fs-3">Syllabus</p>
          <Syllabus course={course}/>
        </div>
      </div>
    </>
  );
};

export default lesson;
