import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import performLogout, { getLoggedUser } from "../../utils/auth";


const Navbar = () => {
    const redirect = useNavigate();
  const [user, setUser] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
const toggle = () => setDropdownOpen((prevState) => !prevState);
  useEffect(() => {
    const loggedUser = getLoggedUser();
    setUser(loggedUser);

  }, []);
  return (
   <>
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container pb-2 pt-2">
          <a className="navbar-brand" href="#">
            Kishul LMS
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo02"
            aria-controls="navbarTogglerDemo02"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
            <form className="">
              <input
                className="form-control"
                type="search"
                placeholder="Search"
                aria-label="Search"
              />
            </form>
          </div>
          {user ? (
            <div className="d-flex flex-end">
              <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="down" >
                <DropdownToggle style={{ borderRadius: "50px" ,backgroundColor:"#0074bf"}}>
                  {user && user.name && user.name.charAt(0)}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem header>Settings</DropdownItem>
                  <DropdownItem>My Profile</DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={(e) => performLogout(e, redirect)}>
                    Log Out
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          ) : (
            <div className="d-flex flex-end">
              <button
                className="btn btn-outline-success mx-3"
                onClick={() => redirect("/")}
              >
                Sign In
              </button>
              <button
                className="btn btn-success mx-3"
                onClick={() =>redirect("/register")}
              >
                Sign Up
              </button>
            </div>

          )}
        </div>
      </nav>
   </>
  )
}

export default Navbar
