import { intersection } from "lodash";


export function getLoggedUser() {

    let user = localStorage.getItem("user") !== undefined ? JSON.parse(localStorage.getItem("user")) : null
    return user; // object
}

export function setLoggedUser(user) { // object
    localStorage.setItem("user", JSON.stringify(user))
}
export function isLoggedIn() {
    return getLoggedUser() !== null;
}
export function getLoggedUserToken() {
    var user = getLoggedUser();
    if (user != null) return user.token;
    return null;
}
const performLogout = (e, redirect) => {
    if (e != null) e.preventDefault();
    localStorage.removeItem("user");
    localStorage.clear();
    // TODO change path later
    redirect("/home");
    redirect(0)
}
export const checkAuth = (error, redirect) => {
    if (error.response && error.response.status === 401) {
        performLogout(null, redirect)
        return false;
    }
    return true;
}
export default performLogout;

export function isArrayWithLength(arr) {
    return Array.isArray(arr) && arr.length;
}


export function getAllowedRoutes(routes) {
    const user = getLoggedUser();
    return routes.filter(({ permission }) => {
        if (!permission) return true;
        else if (!isArrayWithLength(permission)) return true;
        else return intersection(permission, [user && user.role]).length;
    });
}