
import { call, put, takeEvery} from 'redux-saga/effects'
import { courseBySlug, fetchCourses } from './reducer'
import { showLoader, hideLoader } from '../loader/reducer';
import { getCourseBySlug, getCourses } from '../../../services/Api';


const getCoursesFromApi = async () => {
    const courses = await getCourses()
        .then((res) => {
            console.log("Course List from API", res.data.data.courses);
            return res.data.data.courses
        })
        .catch((error) => {
            console.log(error);
            //TODO return
            return [];
        });
    return courses;
}
const getCourseFromApi = async (slug) => {

    const courses = await getCourseBySlug(slug).then((res) => {
        console.log("fetchCourseBYslug from Api", res.data.data.course,slug);
        return res.data.data.course
    })
        .catch((error) => {
            console.log(error);
            //TODO return
            return [];
        });
    return courses;
}
function* fetchCourse() {
    try {
        yield put(showLoader());

        const fetchedCourses = yield getCoursesFromApi()
        console.log("fetchCourse", fetchedCourses);
        yield put(fetchCourses(fetchedCourses))
        yield put(hideLoader());
    } catch (e) {
        console.log(e)
    }
}
function* fetchCourseBySlug(action) {
    try {
        yield put(showLoader());
        const { slug } = action.payload
        console.log("slug",slug)

        const coursesBySlug = yield  call(getCourseFromApi, slug);
        console.log("fetchCourse", coursesBySlug);
        yield put(courseBySlug(coursesBySlug))
        yield put(hideLoader());
    } catch (e) {
        console.log(e)
    }
}

function* courseSaga() {
    yield takeEvery('FETCH_COURSE', fetchCourse);
    yield takeEvery('FETCH_COURSE_BY_SLUG', fetchCourseBySlug);
}
export default courseSaga