import { all, call } from "redux-saga/effects"
import courseSaga from "../modules/course/watcher"
import quizSaga from "../modules/quiz/watcher"

function* rootSaga() {
    yield all([
        call(courseSaga),
        call(quizSaga)
    ])
}

export default rootSaga