import React, { useEffect, useState } from "react";
import {
  Card,
  CardText,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { hideLoader, showLoader } from "../../redux/modules/loader/reducer";
import { Link, useParams } from "react-router-dom";

const Exam = () => {
  const examDurationInSeconds = 2 * 60 * 60; // 2 hours in seconds
  const [remainingTime, setRemainingTime] = useState(examDurationInSeconds);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [currentQuestionPosition, setCurrentQuestionPosition] = useState(0);
  const [showSkipButton, setShowSkipButton] = useState(false);
  const [showPreviousButton, setShowPreviousButton] = useState(false);

  const quiz = useSelector((state) => {
    return state.quiz.quiz;
  });
  let { id } = useParams();
  const dispatch = useDispatch();
  const action = { type: "FETCH_QUIZ_BY_ID", payload: { id } };
  useEffect(() => {
    fetchQuiz();

    const timer = setInterval(() => {
      setRemainingTime((prevTime) => prevTime - 1);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    console.log("Exam useEffect quiz ", quiz);
    if (quiz != null && quiz.questions != null)
      setCurrentQuestion(quiz.questions[0]);
  }, [quiz]);

  const loadNextQuestion = () => {
    let newPosition = currentQuestionPosition;
    newPosition++;
    if (
      quiz != null &&
      quiz.questions != null &&
      quiz.questions.length > newPosition
    ) {
      setCurrentQuestion(quiz.questions[newPosition]);
      setCurrentQuestionPosition(newPosition);
      setShowPreviousButton(true);
      setShowSkipButton(false);
    }
  };
  const loadPreviousQuestion = () => {
    let newPosition = currentQuestionPosition;
    newPosition--;
    if (newPosition >= 0 && newPosition < quiz.questions.length) {
      setCurrentQuestion(quiz.questions[newPosition]);
      setCurrentQuestionPosition(newPosition);
      setShowPreviousButton(newPosition > 0);
      setShowSkipButton(quiz.questions[newPosition].answer == null);
    }
  };
  const skipQuestion = () => {
    let newPosition = currentQuestionPosition;
    newPosition++;
    if (newPosition < quiz.questions.length) {
      setCurrentQuestion(quiz.questions[newPosition]);
      setCurrentQuestionPosition(newPosition);
      setShowPreviousButton(true);
    }
  };
  // quiz.questions[currentPosition].answer = option._id;

  // if answer !=null ? green : grey

  const fetchQuiz = () => {
    dispatch(showLoader());
    dispatch(action);
    dispatch(hideLoader());
  };
  const handleOptionSelect = (option) => {
    let quizCopy = {...quiz}
    quizCopy.questions[currentQuestionPosition].answer = option._id;
    // redux update quizcopy
    // setSelectedOption(option);
    setShowPreviousButton(true);
    setShowSkipButton(false);
  };
  const handleClearAnswer = (event) => {
    let quizCopy = {...quiz}
    quizCopy.questions[currentQuestionPosition].answer = null;
    // redux update quizcopy
  };
  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;
    return `${hours}:${String(minutes).padStart(2, "0")}:${String(
      seconds
    ).padStart(2, "0")}`;
  };
  return (
    quiz && (
      <Container>
        <Row className="justify-content-center align-items-center mt-5">
          <Col md="6">
            <Card>
              <CardBody>
                <CardTitle tag="h6" className="text-center mb-2">
                  {quiz.name}
                </CardTitle>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="h6 ">
                    Question {currentQuestionPosition + 1}
                  </div>
                  <div className="h6 ">Time: {formatTime(remainingTime)}</div>
                </div>
                <hr style={{ height: "2px" }} />
                <CardText style={{ height: "70vh" }}>
                  <Row className="justify-content-center">
                    {currentQuestion && (
                      <Col md="10">
                        <div>
                          <div className="row align-items-center">
                            <div
                              className="mb-4 "
                              style={{ fontSize: "20px", fontWeight: "600" }}
                            >
                              {currentQuestion.name}
                            </div>
                            {currentQuestion.options &&
                              currentQuestion.options.map((option, index) => (
                                <div
                                  className="form-check form-check-inline mb-3"
                                  key={index}
                                >
                                  <input
                                    className="form-check-input mx-1"
                                    type="radio"
                                    name="option"
                                    value={option.option} // set the value of option
                                    checked={quiz.questions[currentQuestionPosition].answer == option.option} // Set checked based on selectedOption
                                    onChange={() => handleOptionSelect(option)}
                                    style={{ fontSize: "16px" }}
                                  />
                                  <label
                                    className="form-check-label mx-1"
                                    htmlFor="option"
                                    style={{ fontSize: "16px" }}
                                  >
                                    {option.option}
                                  </label>
                                </div>
                              ))}
                            {quiz.questions[currentQuestionPosition].answer && (
                              <Link
                                style={{
                                  color: "purple",
                                  fontSize: "18px",
                                  fontWeight: "500",
                                }}
                                className="mx-3"
                                onClick={handleClearAnswer}
                              >
                                Clear Answer
                              </Link>
                            )}
                          </div>
                        </div>
                      </Col>
                    )}
                  </Row>
                </CardText>
              </CardBody>
              <div className="d-flex justify-content-between col-12 mb-3">
                {showPreviousButton && (
                  <Button
                    className="text-center px-5 mx-2 rounded-pill"
                    color="primary"
                    id="previousQuestion"
                    onClick={loadPreviousQuestion}
                  >
                    Previous
                  </Button>
                )}
                {showSkipButton && (
                  <Button
                    className="text-center px-5 mx-2 rounded-pill"
                    color="primary"
                    id="skipQuestion"
                    onClick={() => skipQuestion()}
                  >
                    Skip
                  </Button>
                )}
                {quiz.questions[currentQuestionPosition].answer && (
                  <Button
                    className="text-center px-5 mx-2 rounded-pill"
                    color="primary"
                    id="nextQuestion"
                    onClick={() => loadNextQuestion()}
                  >
                    Next
                  </Button>
                )}
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  );
};

export default Exam;
