import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { login } from "./services/Api";
import { setLoggedUser } from "./utils/auth";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const redirect = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();

    login(email, password)
      .then((response) => {
        try {
          console.log("Login response", response);
          setLoggedUser(response.data.data.user);
          redirect("/home");
        } catch (err) {
          console.log("login error", err);
          console.error(err);
          alert("Invalid Username or Password");
        }
      })
      .catch((error) => {
        console.log(error);
        alert("Invalid Username or Password");
      });
  };
  return (
    <div className="container-fluid">
      <div className="row d-flex align-items-center justify-content-center ">
        <div className="card login-card">
          <div className="card-body">
            <form method="post">
              <h2 className="text-center mt-5 mb-3">Login</h2>
              <div className="form-floating mb-3">
                <input
                  type="email"
                  className="form-control "
                  name="email"
                  value={email}
                  id="floatingInput"
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="name@example.com"
                />
                <label htmlFor="floatingInput">Email address</label>
              </div>
              <div className="form-floating">
                <input
                  type="password"
                  className="form-control "
                  name="password"
                  id="floatingPassword"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                />
                <label htmlFor="floatingPassword">Password</label>
              </div>

              <button
                type="submit"
                className="btn btn-primary mt-3 "
                name="create"
                onClick={handleSubmit}
              >
                Log In
              </button>

              <p className="text-center  mt-2 ">
                or
                <a href="# " className="btn text-center">
                  Forgot Password
                </a>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
