import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    courses: [],
    course: {}
}
const courseReducer = createSlice({
    name: "course",
    initialState,
    reducers: {
        fetchCourses(state, action) {
            console.log("course/reducer courseReducer", action);
            const newCourses = action.payload;
            // Handle the case where newName is undefined
            if (newCourses) {
                const newState = { ...state, courses: newCourses }
                return newState;
            }
            // Return the state
            return state;
        },
        courseBySlug(state, action) {
            const newCourse = action.payload;
            // Handle the case where newName is undefined
            if (newCourse) {
                const newState = { ...state, course: newCourse }
                return newState;
            }
            // Return the state
            return state;
        },
    }
})

export const { fetchCourses, courseBySlug } = courseReducer.actions
export default courseReducer.reducer