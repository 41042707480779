import React from 'react';


function QuillFormattedText({ html }) {
    // const formattedText = React.useMemo(() => {
    //   const parser = new DOMParser();
    //   const parsedHtml = parser.parseFromString(html, 'text/html');
    //   return parsedHtml.body.textContent;
    // }, [html]);

    // return <div>{formattedText}</div>;
    return <div dangerouslySetInnerHTML={{ __html: html }} />;

  }

  export default QuillFormattedText;