import { createSlice } from '@reduxjs/toolkit';

const initialState = false;

const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    showLoader: (state) => {
      console.log("loadingSlice, showLoader", state);
      return true;
    },
    hideLoader: (state) => {
      return false;
    },
  },
});

export const { showLoader, hideLoader } = loadingSlice.actions;

export default loadingSlice.reducer;