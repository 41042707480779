import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  quizzes: [],
  quiz: [],
};
const quizReducer = createSlice({
  name: "quiz",
  initialState,
  reducers: {
    fetchQuizzes(state, action) {
      console.log("quiz/reducer quizReducer", action);
      const newQuizzes = action.payload;
      // Handle the case where newName is undefined
      if (newQuizzes) {
        const newState = { ...state, quizzes: newQuizzes };
        return newState;
      }
      // Return the state
      return state;
    },
    fetchQuiz(state, action) {
      console.log("quiz/reducer fetchquizByIdReducer", action);
      const newQuiz = action.payload;
      if (newQuiz) {
        const newState = { ...state, quiz: newQuiz };
        return newState;
      }
      return state;
    },
    updateQuiz(state, action) {
      const newQuiz = action.payload;
      if (newQuiz) {
        const newQuiz = { ...state, quiz: newQuiz };
        return state;
      }
    },
  },
});

export const { fetchQuizzes, fetchQuiz, updateQuiz } = quizReducer.actions;
export default quizReducer.reducer;
