
import { call, put, takeEvery } from 'redux-saga/effects'
import { fetchQuizzes, fetchQuiz } from './reducer'
import { showLoader, hideLoader } from '../loader/reducer';
import { getQuizById, getQuizList } from '../../../services/Api';


const getQuizFromApi = async () => {
    const quizzes = await getQuizList()
        .then((res) => {
            console.log("Quiz List", res.data.data.quizzes);
            return res.data.data.quizzes
        })
        .catch((error) => {
            console.log(error);
            return error
        });
    return quizzes;
}
const getQuiz = async (id) => {
    try {
        const quiz = await getQuizById(id);
        console.log("Quiz", quiz.data.data.quiz);
        return quiz.data.data.quiz;
    } catch (error) {
        console.log(error);
        return error
    }
}
function* fetchQuizList() {
    try {
        yield put(showLoader());
        const fetchedQuizzes = yield getQuizFromApi()
        console.log("fetchQuiz from API", fetchedQuizzes);
        yield put(fetchQuizzes(fetchedQuizzes))
        yield put(hideLoader());
    } catch (e) {
        console.log(e)
    }
}
function* fetchQuizById(action) {
    try {
        yield put(showLoader());
        const { id } = action.payload; // Destructure the id property from action.payload
        const fetchedQuiz = yield call(getQuiz, id)
        console.log("getQuiz by ID", fetchedQuiz)
        yield put(fetchQuiz(fetchedQuiz))
        yield put(hideLoader())
    } catch (e) {
        console.log(e);
    }
}
function* quizSaga() {
    yield takeEvery('FETCH_QUIZ', fetchQuizList);
    yield takeEvery('FETCH_QUIZ_BY_ID', fetchQuizById);
}
export default quizSaga
