import axios from "axios";

const api_base_url = process.env.REACT_APP_API_URL;
console.log("API BASE URL", api_base_url);

export const login = async (email, password) => {
  return await axios.post(
    `${api_base_url}/admin/users/login`,
    {
      email,
      password,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json, text/plain, /",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
      },
    }
  );
};
export const register = async (data) => {
  return await axios.post(`${api_base_url}/admin/users`, data, {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
  });
};
export const getCourses = async () => {
  return await axios.get(`${api_base_url}/admin/courses`, {});
};
export const getCourse = async (id) => {
  return await axios.get(`${api_base_url}/admin/courses/${id}`, {});
};
export const getCourseBySlug = async (slug) => {
  return await axios.get(`${api_base_url}/courses/${slug}`, {});
};
export const getVideo = async (courseId, sectionId, videoId) => {
  return await axios.get(
    `${api_base_url}/admin/courses/${courseId}/sections/${sectionId}/lesson/${videoId}`,
    {}
  );
};
export const getLesson = async (courseId, sectionId, lessonId) => {
  return await axios.get(
    `${api_base_url}/admin/courses/${courseId}/sections/${sectionId}/lessons/${lessonId}`,
    {}
  );
};
export const getSections = async (id) => {
  return await axios.get(`${api_base_url}/admin/courses/${id}/sections`, {});
};
export const getQuizList = async () => {
  return await axios.get(`${api_base_url}/admin/quizzes`, {});
};
export const getQuizById = async (id) => {
  return await axios.get(`${api_base_url}/admin/quizzes/${id}`, {});
};
